import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Valine from 'gatsby-plugin-valine'; // import the module
import './comment.css'; // Import regular stylesheet
import Subscribe from './subscribe.js';
<p>&nbsp;</p>;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorBanner = makeShortcode("AuthorBanner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Subscribe mdxType="Subscribe" />
    <p>&nbsp;</p>
    <AuthorBanner mdxType="AuthorBanner"></AuthorBanner>
    <p>&nbsp;</p>
    <Valine serverURLs="https://cnlqoen5.api.lncldglobal.com" highlight="true" lang="en" avatar="gavatar" mdxType="Valine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      