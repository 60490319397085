import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

class Subscribe extends Component {
  state = {
    email: "",
    statusMsg: "",
    statusMsgColor: "green",
    subscribing: false,
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ statusMsg: "", subscribing: true })
    let { email } = this.state
    addToMailchimp(
      email
    )
      .then(data => {
        if(data.result == "success"){
           this.setState({
              statusMsg: "Your subscription was successful!",
              statusMsgColor: rgb(0, 132, 132),
              email: "",
              subscribing: false,
            });
          } else {
            this.setState({
              statusMsg: "This email has already been subscribed.",
              statusMsgColor: "red",
              subscribing: false,
            });
      }}).catch(err => {
        this.setState({
          statusMsg: "Thanks for the subscribing.",
          statusMsgColor: "green",
          subscribing: false,
        })
      })
  }

  render() {
    let { statusMsg, subscribing } = this.state
    let btnCTA = subscribing ? "Subscribing" : "Subscribe"
    return (
      <div className="subscribe">
        <h4>Subscribe to receive updates on new posts</h4>
        <form onSubmit={this.handleSubmit}>
          <div className="row text-center mx-auto">
            <div className="col-sm-12 mx-auto my-1 sub-input-cont">
              <input
                type="email"
                autoComplete="true"
                placeHolder="E-mail address"
                required
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>

            <div className="col-sm-12 mx-auto my-1 sub-button-cont">
              <button type="submit">{btnCTA}</button>
            </div>
            {statusMsg && (
              <div className="col-12">
                <p
                  className="text-left"
                  style={{ color: this.state.statusMsgColor }}>
                  {statusMsg}
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export default Subscribe